<template>
    <basic-page class="storia" :pagedata="this.pageData" bannerimg="/img/la-storia-banner.jpg">
        <div class="container-beige intro container">
            <h3 class="occhiello occhiello-beige">
                {{ lang == 'it' ? 'Il nostro viaggio' : 'Our Journey' }}
            </h3>
            <h2>
                {{ lang == 'it' ? 'Un\'idea lunga un Secolo' : 'A Century-long Idea' }}
            </h2>
        </div>
        <div class="container" v-if="this.pageData && this.pageData.field_page_ui_testo">
            <div :class="['item-container', 'item-' + index]"
                v-for="(s, index) in this.pageData.field_page_ui_testo[0].attributes.field_ui_testo"
                :key="'storia' + index">
                <div class="images" v-parallax="offsetImages">
                    <img :src="'/img/la-storia/img-' + index + '.jpg'" alt="">
                    <div class="filigrana filigrana-img" v-parallax="offsetFiligrane"></div>
                </div>
                <div class="text" v-parallax="offsetText">
                    <div class="filigrana filigrana-text" v-parallax="offsetFiligrane"></div>
                    <h2>{{ s.summary }}</h2>
                    <p v-html="s.processed"></p>
                </div>
            </div>
        </div>
    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import {
    fetchSinglePage,
    getTranslation
} from '../libs/drupalClient'
export default {
    components: {
        BasicPage
    },
    name: 'contatti',
    data: () => {
        return {
            pageData: {
                attributes: {}
            },
            w: null,
            currentPageId: "e48e68b2-4cfd-4bb0-8557-fc665636c547"
        }
    },
    methods: {},
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        offsetText() {
            if (this.w > 1200)
                return 0.5
            else
                return 0.4
        },
        offsetImages() {
            if (this.w > 1200)
                return -0.1
            else
                return 0.2
        },
        offsetFiligrane() {
            if (this.w > 1200)
                return -0.1
            else
                return -0.1
        }
    },
    mounted() {
        fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            })
    },
    watch: {
        lang() {
            getTranslation(this.$route.name, this.lang)
                .then(res => {
                    this.$router.push({ path: res })
                })

            fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../styles/colors";


.item-container {
    margin: 5rem 0rem;
    display: flex;
    justify-content: center;
}

.storia {
    .container {
        position: relative;
    }

    .filigrana {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:before {
            position: absolute;
        }
    }

    .images {
        position: relative;
        z-index: -1;

        &:before {
            position: absolute;
        }
    }

    .text {
        width: 600px;
        z-index: 2;
        position: relative;
        padding: 0rem 4rem;

        h2 {
            font-size: 3.125rem;
            line-height: 3rem;
            font-family: "Cinzel";
            margin-bottom: 2rem;
        }

        ::v-deep p {
            font-weight: 300;
            text-align: left;
        }

        &:before {
            position: absolute;
        }
    }


    .item-container:nth-child(even) {
        text-align: left;

        ::v-deep p {
            font-weight: 300;
        }
    }
}

.item-0 {
    margin: 35rem 2rem 20rem 2rem;
    justify-content: flex-start;

    .filigrana-text {
        z-index: -1;
    }

    .filigrana-text::before {
        content: "";
        width: 316px;
        height: 316px;
        position: absolute;
        z-index: -1;
        left: -120%;
        top: 73%;
        background-image: url("../../public/img/la-storia/img-0-1.png");
    }

    img {
        position: relative;
        margin-top: -6rem;
    }

    .text:before {
        content: "1933";
        font-size: 10rem;
        font-family: "Cinzel";
        right: 100%;
    }
}

.item-1 {
    flex-direction: row-reverse;
    margin-bottom: 19rem;
    justify-content: flex-start;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;

        p ::v-deep p {
            text-align: right;
        }

        h2 {
            font-size: 5rem;
        }
    }

    .filigrana-text::before {
        content: "";
        width: 416px;
        height: 456px;
        z-index: 1;
        right: 50%;
        top: 40%;
        background-image: url("../../public/img/la-storia/img-1-1.png");
    }

    .filigrana-img::before {
        content: "";
        width: 316px;
        height: 316px;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 70%;
        background-image: url("../../public/img/la-storia/img-1-2.png");
    }
}

.item-2 {
    margin: 12rem 0rem 19rem 0rem;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 0%;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            font-size: 5.625rem;
        }
    }

    .text {
        margin-top: 5rem;

    }

    .images {
        margin-left: 0%;
    }
}

.item-3 {
    margin-right: 10%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-bottom: 21rem;


    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            font-size: 6.875rem;
        }
    }

    .text {
        margin-top: 7rem;
        text-align: right;

        ::v-deep p {
            text-align: right;
        }
    }

    .images {
        &::before {
            z-index: 0;
            position: absolute;
            right: 110%;
            top: 65%;
            content: url("../../public/img/la-storia/img-2-2.png");
        }
    }

    .filigrana-text::before {
        z-index: -44;
        left: 165%;
        top: 45%;
        content: url("../../public/img/la-storia/img-2-1.png");
    }
}

.item-4 {
    margin-right: 0%;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 48rem;
    margin-top: 29rem;
    text-align: left;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            color: $mainColor;
            font-size: 6.875rem;
        }

        ::v-deep p {
            text-align: left;
        }
    }

    .text {
        margin-top: 7rem;

        &::after {
            z-index: -1;
            right: 20%;
            font-family: "Cinzel";
            top: -40%;
            color: rgb(98, 98, 98);
            opacity: 0.05;
            font-size: 13.75rem;
            position: absolute;
            content: "2004";
        }
    }

}

.item-5 {
    margin-left: 0%;
    justify-content: flex-start;
    margin-bottom: 22rem;

    .text {
        display: flex;
        flex-direction: column;

        &::before {
            z-index: -1;
            left: 3rem;
            top: 10%;
            font-size: 13.75rem;
            content: url("../../public/img/la-storia/img-4-1.png");
        }

        h2 {
            font-size: 5.625rem;
        }
    }

    .images {
        margin-top: -15rem;
        margin-right: -2rem;
    }
}

//domori
.item-6 {
    margin-bottom: 25rem;
    justify-content: flex-start;
    flex-direction: row-reverse;

    .text {
        display: flex;
        margin-bottom: 21rem;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        h2 {
            font-size: 5.625rem;
        }
    }

    .text {
        &::before {
            z-index: -1;
            left: -16rem;
            top: 2%;
            content: url("../../public/img/la-storia/img-5-3.png");
        }

        &::after {
            z-index: -1;
            right: 4rem;
            position: absolute;
            top: 112%;
            content: url("../../public/img/la-storia/img-5-1.png");
        }
    }

    .filigrana-text {
        &::before {
            z-index: -1;
            right: -130%;
            top: 140%;
            content: url("../../public/img/la-storia/img-5-2.png");
        }
    }

    .filigrana-img {
        &::after {
            position: absolute;
            z-index: -1;
            right: 0;
            top: -50%;
            content: url("../../public/img/la-storia/img-5-4.png");
        }
    }

    .images {
        margin-right: 0rem;
    }
}

.item-7 {
    margin-bottom: 17rem;
    justify-content: flex-start;
    align-items: flex-end;

    .text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        h2 {
            font-size: 5.625rem;
        }
    }

    .text {
        &::after {
            z-index: -1;
            left: 4rem;
            position: absolute;
            bottom: -70%;
            content: url("../../public/img/la-storia/img-6-3.png");
        }
    }


    .filigrana-text::before {
        z-index: -1;
        right: 20%;
        top: -30%;
        content: url("../../public/img/la-storia/img-8-3.png");
    }


    .images {
        margin-right: 0rem;
    }
}

.item-8 {
    margin-bottom: 29rem;
    flex-direction: row-reverse;
    margin-right: 10%;
    margin-top: 24rem;

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        h2 {
            font-size: 5.625rem;
        }
    }

    .text {
        &::after {
            z-index: -1;
            right: 3rem;
            position: absolute;
            top: 59%;
            transform: scale(00.7);
            content: url("../../public/img/la-storia/img-7-1.png");
        }

        .filigrana-img {
            &::before {
                z-index: -1;
                left: 50%;
                top: 70%;
                content: url("../../public/img/la-storia/img-6-1.png");
            }
        }
    }

    .filigrana-text::before {
        z-index: -1;
        right: 0rem;
        top: 0%;
        content: url("../../public/img/la-storia/img-7-2.png");
    }

    .filigrana-text::after {
        z-index: -1;
        left: -36%;
        bottom: -10%;
        position: absolute;
        content: url("../../public/img/la-storia/img-7-4.png");
    }

    .filigrana-img {
        &::before {
            z-index: -1;
            left: 90%;
            top: 40%;
            content: url("../../public/img/la-storia/img-7-3.png");
        }
    }

    .images {
        margin-right: 0rem;
    }
}

.item-9 {
    margin-bottom: 18rem;
    justify-content: flex-start;

    .text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 13rem;

        h2 {
            font-size: 5.625rem;
        }

        &::after {
            z-index: -1;
            left: 4rem;
            position: absolute;
            top: 100%;
            content: url("../../public/img/la-storia/img-9-2.png");
        }
    }

    .filigrana-img {
        &::before {
            z-index: -1;
            left: -20%;
            transform: scale(0.8);
            top: -30%;
            content: url("../../public/img/la-storia/img-8-2.png");
        }
    }

    .images {
        margin-right: 0rem;

        img {
            max-width: 600px;
        }
    }
}

.item-10 {
    margin-bottom: 30rem;
    justify-content: flex-end;
    text-align: left !important;

    .text {
        &::after {
            z-index: -1;
            left: 4rem;
            position: absolute;
            top: 52%;
            content: url("../../public/img/la-storia/img-8-1.png");
        }
    }

    .text {
        margin-top: 7rem;
    }
}

.item-11 {
    margin-right: 10%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 30rem;
    margin-top: 35rem;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            color: $mainColor;
            font-size: 6.875rem;
        }
    }

    .text {

        &::after {
            z-index: -1;
            //left: 4rem;
            position: absolute;
            bottom: auto;
            content: '';
            top: 90%;
            content: ''; //url("../../public/img/la-storia/img-10-1.png");
            background: url("../assets/logo-dark.svg");
            width: 300px;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::before {
            z-index: -1;
            position: absolute;
            left: 78%;
            transform: scale(0.8);
            top: -30%;
        }

        .filigrana-text {
            z-index: -1;
            left: -30%;
            position: absolute;
            content: "";
            top: -100%;
            width: auto;
            height: auto;
            content: url("../../public/img/la-storia/img-10-2.png");
        }

    }

}


.item-12 {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15rem;
    margin-top: 10rem;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            font-size: 6.875rem;
        }
    }

    .text {
        &::after {
            z-index: -1;
            position: absolute;
            bottom: auto;
            content: '';
            top: 70%;
            content: '';
            background: url("../assets/logo-pintaudi-storia.jpg");
            width: 220px;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .images {
        img {
            width: 100%;
        }

        .filigrana-img {
            &::before {
                z-index: -1;
                left: 32%;
                transform: scale(0.5);
                top: 34%;
                content: url("../assets/prodotto-pintaudi.png");
            }
        }
    }

    .text {
        width: 360px;
        margin-left: 15rem;
    }
}


.item-13 {
    margin-right: 10%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 12rem;
    margin-top: 0;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            color: $mainColor;
            font-size: 6.875rem;
        }
    }

    .text {

        &::after {
            z-index: -1;
            //left: 4rem;
            position: absolute;
            bottom: auto;
            content: '';
            top: 120%;
            content: ''; //url("../../public/img/la-storia/img-10-1.png");
            background: url("../assets/logo-achillea.jpg");
            width: 180px;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::before {
            z-index: -1;
            position: absolute;
            left: 78%;
            transform: scale(0.8);
            top: -30%;
        }



    }

}


.item-14 {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15rem;
    margin-top: 0rem;

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            font-size: 6.875rem;
        }
    }

    .text {
        &::after {
            z-index: -1;
            position: absolute;
            bottom: auto;
            content: '';
            top: 130%;
            content: '';
            background: url("../assets/logo-incantalia.jpg");
            width: 220px;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .images {
        img {
            width: 100%;
        }
    }

    .text {
        width: 360px;
    }
}




@media (max-width:1024px) {
    .item-14 {
        .images::after {
            background: url("../assets/spiga_02.png");
            position: absolute;
            bottom: auto;
            content: "";
            width: 120px;
            height: 180px;
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -1%;
            left: -1%;
            z-index: 9;
            transform: scaleX(-1) rotate(25deg);
        }
    }
}

@media (min-width:1200px) {
    .item-13 {
        margin-top: 34rem;

        .images img {
            width: 520px;
        }

        .text::after {
            position: relative;
            top: 30px;
        }
    }

    .item-14 {
        justify-content: center;
        margin-top: 28rem;

        .text::after {
            position: relative;
            top: 10%;
        }

        .images img {
            margin-top: -120px;
        }


        .images::after {
            background: url("../assets/spiga_02.png");
            position: absolute;
            bottom: auto;
            content: "";
            width: 310px;
            height: 390px;
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -19%;
            left: -19%;
            z-index: 9;
            transform: scaleX(-1) rotate(25deg);
        }
    }
}

@media (min-width:1400px) {
    .item-14 {

        .filigrana-text::before {
            background: url("../assets/apple.png");
            z-index: -1;
            position: absolute;
            bottom: auto;
            content: "";
            width: 290px;
            height: 390px;
            transform: rotate(9deg);
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -70%;
            right: 290px;
        }
    }

}

@media (min-width:1500px) {
    .item-11 .text::after {
        content: "";
        top: 70%;
    }

    .item-13 {
        margin-top: 38rem;

        .images {
            left: -13%;

            img {
                width: 620px;
            }
        }
    }

    .item-14 {
        justify-content: flex-start;
        margin-top: 30rem;

        .text {
            padding-top: 7rem;
        }

        .images img {
            width: 700px;

        }

        .images::after {
            width: 340px;
            bottom: 11%;
            left: -23%;
        }

        .filigrana-text::before {
            bottom: -40%;
            right: -69%;
        }
    }
}

@media (max-width:1500px) {
    .item-6 .filigrana-text::before {
        top: 180%;
    }

    .item-6 .filigrana-text::before {
        transform: scale(0.8);
    }

    .item-6 img {
        max-width: 570px;
    }

    .item-7 img {
        max-width: 570px;
    }

    .item-8 .filigrana-text::after {
        left: -20%;
        bottom: -12%;
        content: url("../../public/img/la-storia/img-7-4.png");
    }

    .item-11 .text::after {
        z-index: -1;
        //left: 4rem;
        position: absolute;
        //bottom: -50%;
        //top: unset;
        content: "";
        width: 200px;
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../assets/logo-dark.svg'); //url("../../public/img/la-storia/img-10-1.png");
    }

    .item-11 {
        margin-bottom: 29rem;
        margin-top: 20rem;
    }


}

@media (max-width:1200px) {
    .storia .container {
        padding-left: 0;
        padding-right: 0;
    }

    .storia .images {
        width: 40%;

        img {
            width: 100%;
        }
    }

    .storia .text {
        width: 60%;
        padding: 0rem 2rem;

        h2 {
            font-size: 5rem !important;
            line-height: 4.4rem;
        }
    }

    .item-0 .text:before {
        top: 50%;
    }

    .item-0 {
        margin: 32rem 0rem 35rem 0rem;

        .filigrana-text::before {
            transform: scale(0.7);
            left: -90%;
        }
    }

    .item-2 {
        margin: 12rem 0rem 14rem 0rem;
    }

    .item-3 {
        margin-right: 0%;
    }

    .item-4 {
        margin-bottom: 42rem;
    }

    .item-5 {
        margin-bottom: 33rem;
    }

    .item-6 {
        justify-content: flex-end;
        flex-direction: row-reverse;
        margin: 5rem 5rem 25rem 0rem;

        .filigrana-text::before {
            transform: scale(0.5);
        }

        .text {
            &::after {
                right: 1.5rem;
            }
        }
    }

    .item-7 {
        .text::after {
            left: unset;
            right: 50%;
        }
    }

    .item-8 {
        img {
            max-width: 230px;
        }

        .text::after {
            width: 200px;
            right: 1rem;
            top: 55%;
            height: 200px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            content: "";
            background-image: url("../../public/img/la-storia/img-7-1.png");
        }

        .filigrana-text::after {
            left: -20%;
            bottom: -10%;
            transform: scale(0.7);
        }

        .filigrana-img::before {
            left: 61%;
            transform: scale(0.6);
        }
    }

    .item-9 .filigrana-img::before {
        z-index: -1;
        left: -20%;
        top: 0%;

        width: 400px;
        height: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: "";
        background-image: url("../../public/img/la-storia/img-8-2.png");
    }

    .item-10 {
        .images {
            justify-content: flex-end;
            display: flex;
        }

        img {
            max-width: 280px;
        }

        .text::after {
            left: 2rem;
            top: 33%;
        }
    }

    .item-11 {
        margin-bottom: 38rem;
        margin-top: 13rem;

        .text::after {
            top: 100%;
        }
    }


    .item-12 {
        margin-bottom: 15rem;
        margin-top: 14rem;


        .images {
            width: 300px;
        }

        .text {
            width: 360px;
        }
    }

    .item-12 {
        .text::after {
            top: 86%;
            width: 150px;
        }

        .images {
            width: 412px;
        }

        .images .filigrana-img::before {
            left: 28%;
            transform: scale(0.4);
            top: 14%;
        }
    }


}

@media (max-width:1024px) {
    .item-4 .filigrana-img::before {
        display: none;
    }

    .storia .container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .storia .text {
        padding: 0rem 0rem 0rem 0rem;

        p {
            text-align: left;

            ::v-deep p {
                text-align: left;
            }
        }
    }

    .storia .images {
        display: flex;

        img {
            width: 90%;
            margin: 0 auto;
            max-height: 500px;
            width: auto;
        }
    }

    .storia .text h2 {
        text-align: left;
        margin-bottom: 1rem;
        font-size: 3.5rem !important;
        line-height: 3.5rem;
    }

    .storia .text ::v-deep p {
        font-size: 1.125rem;
    }

    .item-container {
        flex-direction: column;
    }

    .storia .images,
    .storia .text {
        width: 100%;
    }

    .storia .text {
        padding: 2rem 0rem 0rem 0rem;
    }

    .item-0 {
        margin: 6rem 0rem 9rem 0rem;

        img {
            position: relative;
            margin-top: 0rem;
        }

        .text {

            &:before {
                right: 0%;
                position: relative;
                top: -50%;
            }
        }

        .filigrana-text::before {
            left: 80%;
            top: -27%;
        }
    }

    .item-1 {
        margin-bottom: 4rem;

        .text {
            align-items: flex-start;
        }

        .filigrana-img::before {
            left: 70%;
            top: 70%;
        }

        .filigrana-text::before {
            right: 70%;
            top: -230%;
        }
    }

    .item-2 {
        margin: 0rem 0rem 5rem 0rem;
    }

    .item-3 {
        margin-bottom: 7rem;

        .text {
            margin-top: 2rem;
        }
    }

    .item-4 {
        margin-right: 0%;
        margin-bottom: 24rem;
        margin-top: 8rem;

        .text {
            margin-top: 1rem;
        }
    }

    .item-5 {
        margin-bottom: 13rem;

        .text::before {
            left: -1rem;
            top: 16%;
        }
    }

    .item-6 {
        text-align: left;
        margin: 5rem 0rem 0rem 0rem;

        .text {
            justify-content: flex-start;
            margin-bottom: 8rem;
            align-items: flex-start;

            &:after {
                right: auto;
            }
        }
    }

    .item-7 {
        margin-bottom: 0rem;

        .text::after {
            right: unset;
            left: 0;
        }
    }

    .item-8 {
        margin-top: 6rem;
        margin-right: 0%;
        margin-bottom: 8rem;

        .text {
            align-items: flex-start;

            &::after {
                right: 0;
                position: relative;
                top: 0;
            }
        }

        .images img {
            height: 50%;
            width: auto;
            margin: 0 auto;
            max-height: 500px;
        }

        .filigrana-text::after {
            display: none;
        }
    }

    .item-9 {
        margin-bottom: 14rem;

        .text {
            margin-bottom: 0rem;

            &:after {
                left: 0rem;
            }
        }

        .filigrana-img:before {
            display: none;
        }
    }

    .item-10 {
        margin-bottom: 0rem;

        .images {
            img {
                width: auto;
                max-height: 400px;
            }
        }

        .text {
            margin-top: 1rem;

            &::after {
                left: 0;
                position: relative;
                top: 0;
            }
        }
    }

    .item-11 {
        margin-top: 4rem;
        margin-bottom: 7rem;

        .text {
            margin-top: 0rem;

            &::after {
                left: 0;
                position: relative;
                bottom: -30px;
            }
        }
    }




    .item-12 {
        margin-bottom: 15rem;
        margin-top: 8rem;

        .images .filigrana-img::before {
            display: none;
        }

        .text {
            margin: 0;
        }

        .text::after {
            top: 120%;
        }
    }


}

@media (max-width:768px) {
    .storia .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .storia {
        .text {
            h2 {
                font-size: 3.125rem !important;
                line-height: 2.7rem;
            }

            p {
                font-size: 1rem;

                ::v-deep p {
                    font-size: 1rem;
                }
            }

        }

        .images {
            img {
                width: 100%;
                max-height: none;
            }
        }
    }


    .item-0 {
        margin: 3rem 0rem 6rem 0rem;

        .text:before {
            font-size: 4rem;
        }
    }

    .item-0 .filigrana-text::before {
        left: 30%;
        top: -43%;
        transform: scale(0.4);
    }

    .item-2 .text {
        margin-top: 0rem;
    }

    .item-3 {
        .images img {
            width: 80%;
        }
    }

    .item-4 {
        margin-bottom: 20rem;

        .text::after {
            right: 0;
            font-size: 9.75rem;
        }
    }

    .item-5 .text::before {
        left: -1rem;
        top: 35%;
    }

    .item-6 .text::before {
        left: 0;
        transform: scale(0.5);
    }

    .item-6 .filigrana-img::after {
        display: none;
    }

    .item-8 {
        margin-bottom: 0;

        .images img {
            width: 50%;
        }

        .filigrana-img:before {
            z-index: 2;
            left: -10px;
            transform: scale(0.4);
            top: auto;
            bottom: 28px;
            transform-origin: bottom;
        }
    }

    .item-7 .filigrana-text::before {
        z-index: -1;
        left: -20%;
        transform: scale(0.5);
        transform-origin: left;
    }

    .item-8 .text::after {
        right: 30px;
        top: 0;
        height: 100px;
    }

    .item-9 .text:after {
        transform-origin: left;
        transform: scale(0.7);
    }

    .item-10 .text::after {
        transform-origin: top left;
        transform: scale(0.7);
        position: relative;
        display: block;
    }

    .item-11 {
        margin-top: 0rem;
    }

}
</style>